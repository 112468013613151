import React from "react";
import Layout from "../../components/layout";
import { article } from "../../styles/modules/post_template.module.css";
import { darkOrLightColor } from "../../helpers/darkOrLightColor";
import { ZodiacSign } from "../../components/ZodiacSign";

const formatDate = (dateISOString, region, options) => {
  const date = new Date(dateISOString);

  return date.toLocaleDateString(region, options);
};

function Page() {
  const date = `${new Date()}`;

  const textDate = formatDate(date, "pt-BR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const data = {
    week: "01",
    zodiacSigns: [
      {
        signName: "aries",
        portugueseName: "Áries",
        colorName: "Verde Esmeralda",
        colorHex: "#ccddaa",
        description:
          "Hoy, a cor verde esmeralda é a escolha auspiciosa para Áries, pois simboliza renovação e energia positiva. Este tom vibrante ajudará você a enfrentar desafios com determinação e atrairá boa sorte em seus empreendimentos.",
      },
      {
        signName: "taurus",
        portugueseName: "Touro",
        colorName: "Azul Zafiro",
        colorHex: "#336699",
        description:
          "Para Touro, a cor azul zafiro é a da sorte hoje. Representa estabilidade e confiança, oferecendo-lhe uma aura de tranquilidade que permitirá tomar decisões acertadas e atrair prosperidade em seus projetos.",
      },
      {
        signName: "gemini",
        portugueseName: "Gêmeos",
        colorName: "Amarelo Brilhante",
        colorHex: "#ffcc00",
        description:
          "Gêmeos encontrarão fortuna no amarelo brilhante hoje. Essa cor simboliza criatividade e comunicação, fornecendo a energia necessária para expressar suas ideias de maneira clara e eficaz, levando ao sucesso.",
      },
      {
        signName: "cancer",
        portugueseName: "Câncer",
        colorName: "Prata Luminosa",
        colorHex: "#c0c0c0",
        description:
          "Hoje, a cor prata luminosa é a indicada para Câncer. Representa intuição e proteção, proporcionando a tranquilidade emocional necessária. Este tom prateado atuará como um escudo positivo, atraindo boas vibrações ao seu redor.",
      },
      {
        signName: "leo",
        portugueseName: "Leão",
        colorName: "laranja cálido",
        colorHex: "#ff9900",
        description:
          "Leão encontra sua cor da sorte no laranja cálido hoje. Este tom representa vitalidade e confiança em si mesmo, impulsionando-o a se destacar e brilhar em qualquer situação. Aproveite essa energia para alcançar suas metas com sucesso.",
      },
      {
        signName: "virgo",
        portugueseName: "Virgem",
        colorName: "marrom terra",
        colorHex: "#8b4513",
        description:
          "Para Virgem, o marrom terra é a cor afortunada hoje. Simboliza estabilidade e conexão com a natureza, fornecendo uma base sólida para enfrentar qualquer desafio. Com esta cor, você encontrará equilíbrio e harmonia em suas ações.",
      },
      {
        signName: "libra",
        portugueseName: "Libra",
        colorName: "Rosa Suave",
        colorHex: "#ffcccc",
        description:
          "Libra, hoje sua cor da sorte é o rosa suave. Este tom representa amor e harmonia, criando um ambiente propício para relacionamentos positivos. Use esta cor para fortalecer os laços afetivos e atrair paz para sua vida.",
      },
      {
        signName: "scorpio",
        portugueseName: "Escorpião",
        colorName: "Vermelho Intenso",
        colorHex: "#ff0000",
        description:
          "Os Escorpiões encontrarão sua sorte no vermelho intenso hoje. Esta cor representa paixão e determinação, impulsionando você a enfrentar desafios com coragem. Use este tom para atrair a energia necessária e alcançar seus objetivos com sucesso.",
      },
      {
        signName: "sagittarius",
        portugueseName: "Sagitário",
        colorName: "Roxo Real",
        colorHex: "#800080",
        description:
          "Sagitário, hoje o roxo real é sua cor da sorte. Simboliza sabedoria e expansão, oferecendo a inspiração necessária para explorar novas possibilidades. Use este tom para expandir seus horizontes e perseguir seus sonhos com confiança.",
      },
      {
        signName: "capricorn",
        portugueseName: "Capricórnio",
        colorName: "Cinza Claro",
        colorHex: "#d3d3d3",
        description:
          "Para Capricórnio, o cinza claro é a cor afortunada hoje. Representa estabilidade e praticidade, oferecendo uma base sólida para tomar decisões acertadas. Use este tom para abordar suas responsabilidades com determinação.",
      },
      {
        signName: "aquarius",
        portugueseName: "Aquário",
        colorName: "Turquesa Refrescante",
        colorHex: "#00ced1",
        description:
          "Aquário, encontre sua sorte no turquesa refrescante hoje. Este tom simboliza inovação e liberdade, fornecendo a inspiração necessária para seguir seus instintos criativos. Use esta cor para quebrar barreiras e experimentar o sucesso em novos empreendimentos.",
      },
      {
        signName: "pisces",
        portugueseName: "Peixes",
        colorName: "Lavanda Tranquila",
        colorHex: "#e6e6fa",
        description:
          "Peixes, hoje a lavanda tranquila é sua cor da sorte. Representa paz e intuição, criando um ambiente sereno ao seu redor. Use este tom para se conectar com sua intuição e tomar decisões que o guiem para a harmonia e o bem-estar.",
      },
    ],
  };

  return (
    <Layout locale="pt">
      <article className={article}>
        <h1>Cor da sorte para hoje {textDate}</h1>
        <p>
          Bem-vindos ao <strong>horóscopo das cores da sorte para hoje!</strong>{" "}
          Descubra como as tonalidades vibrantes podem influenciar positivamente
          o seu dia de acordo com o seu signo do zodíaco. Junte-se a nós
          enquanto exploramos as cores afortunadas que o cercarão e o
          impulsionarão em direção à prosperidade ao longo desta semana.
        </p>
        <h2>Cores da sorte de acordo com o seu signo:</h2>
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: "1rem",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: "1rem",
          }}
        >
          {data.zodiacSigns.map(sign => (
            <li>
              <article
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gridTemplateRows: "140px 1fr",
                  boxShadow: "0 0 3px 0 gray",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: sign.colorHex,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "1rem",
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  <ZodiacSign
                    sign={sign.signName}
                    color={darkOrLightColor(sign.colorHex)}
                    width={44}
                    height={44}
                  />
                  <span
                    style={{
                      fontSize: "1.2rem",
                      color: darkOrLightColor(sign.colorHex),
                    }}
                  >
                    {sign.colorHex}
                  </span>
                </div>
                <div style={{ padding: ".8rem" }}>
                  <h3
                    style={{
                      fontSize: "1.4rem",
                      margin: "0 0",
                      padding: "0.5rem 0",
                      lineHeight: 1.3,
                    }}
                  >
                    A cor para {sign.portugueseName} hoje é {sign.colorName}.
                  </h3>
                  <p
                    style={{
                      fontSize: ".9rem",
                      margin: ".5rem 0",
                      lineHeight: 1.5,
                    }}
                  >
                    {sign.description}
                  </p>
                </div>
              </article>
            </li>
          ))}
        </ul>
        <p>
          Aproveite ao máximo o poder das cores e deixe-as guiar o seu dia em
          direção à harmonia e ao sucesso. Lembre-se de levar consigo a energia
          positiva que cada tom traz para o seu signo do zodíaco. Que estas
          cores da sorte iluminem o seu dia e tragam a fortuna que procura! Até
          a próxima edição das cores astrais! 🌈✨
        </p>
      </article>
    </Layout>
  );
}

export const Head = () => {
  const date = `${new Date()}`;

  const textDate = formatDate(date, "pt-BR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <>
      <title>Cores da sorte desta semana《Signo do Zodíaco》</title>
      <meta
        name="description"
        content={`Explora o horóscopo de cores para hoje ${textDate} e descubra como o seu signo do zodíaco pode brilhar com os tons afortunados. `}
      />
    </>
  );
};

export default Page;
