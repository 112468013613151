import React from "react";

export const AriesIcon = ({ color = "#000000", width = 44, height = 44 }) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title>aries</title>{" "}
      <path d="M21.561 3.28c-2.684 1.293-4.477 4.116-5.663 7.535-1.186-3.418-2.979-6.242-5.663-7.535-8.595-4.14-11.887 7.734-5.522 12.778h5.281c0 0-1.565-2.74-1.748-3.128-0.281-0.593-1.933-4.882 0.404-3.372 3.163 2.043 3.885 9.337 4.23 12.546 0.347 3.23 0.549 7.495 0.549 7.495h4.936c0 0 0.202-4.265 0.549-7.495 0.345-3.209 1.067-10.503 4.23-12.546 2.338-1.51 0.685 2.78 0.405 3.372-0.183 0.388-1.748 3.128-1.748 3.128h5.281c6.365-5.044 3.073-16.918-5.522-12.778z"></path>{" "}
    </g>
  </svg>
);

export const TaurusIcon = ({ color = "#000000", width = 44, height = 44 }) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title>taurus</title>{" "}
      <path d="M1.927 1.88c0 0 2.092-0.674 5.746 1.226 3.806 1.979 5.343 7.286 8.293 7.354 2.95-0.068 4.488-5.375 8.293-7.354 3.654-1.9 5.746-1.226 5.746-1.226v5.334c0 0-2.375-0.153-3.677 0.843-0.946 0.723-2.822 3.388-4.454 4.997 2.425 1.737 3.991 4.485 3.991 7.578 0 5.252-4.514 9.509-10.083 9.509s-10.083-4.257-10.083-9.509c0-3.184 1.66-6.003 4.208-7.729-1.595-1.62-3.386-4.145-4.303-4.846-1.303-0.996-3.678-0.843-3.678-0.843v-5.334zM11.197 20.622c0 2.496 2.058 4.52 4.597 4.52s4.597-2.024 4.597-4.52-2.058-4.52-4.597-4.52-4.597 2.024-4.597 4.52z"></path>{" "}
    </g>
  </svg>
);

export const GeminiIcon = ({ color = "#000000", width = 44, height = 44 }) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title>gemini</title>{" "}
      <path d="M2.51 24.466c1.48-0.432 2.964-0.801 4.485-1.065v-14.993c-1.521-0.263-3.005-0.633-4.485-1.065v-5.634c4.41 1.288 8.354 1.832 12.976 1.832 4.916 0 9.086-0.594 13.732-2.103v5.652c-1.622 0.527-3.229 0.954-4.91 1.259v15.11c1.681 0.305 3.288 0.732 4.91 1.259v5.652c-4.646-1.509-8.816-2.103-13.732-2.103-4.622 0-8.566 0.544-12.976 1.832v-5.634zM15.486 22.708c0.607 0 1.836 0.010 2.428 0.029v-13.665c-0.592 0.019-1.821 0.029-2.428 0.029-0.494 0-1.613-0.006-2.098-0.019v13.645c0.485-0.012 1.604-0.019 2.098-0.019v0z"></path>{" "}
    </g>
  </svg>
);

export const CancerIcon = ({ color = "#000000", width = 44, height = 44 }) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title>cancer</title>{" "}
      <path d="M2.194 9.079c2.547-4.195 8.742-7.166 15.608-7.166 4.566 0 8.659 1.071 12.382 3.75l0.029 6.040c-5.049-3.851-11.348-5.658-17.402-3.351 1.113 1.212 1.794 2.83 1.794 4.607 0 3.762-3.049 6.812-6.811 6.812s-6.811-3.050-6.811-6.812c-0-1.44 0.447-2.778 1.211-3.879v0zM7.775 14.281c0.773 0 1.401-0.627 1.401-1.401s-0.627-1.401-1.401-1.401-1.401 0.627-1.401 1.401 0.628 1.401 1.401 1.401zM24.055 17.263c-0.774 0-1.401 0.627-1.401 1.401s0.627 1.401 1.401 1.401 1.401-0.627 1.401-1.401-0.627-1.401-1.401-1.401zM29.636 22.466c-2.547 4.195-8.742 7.166-15.607 7.166-4.567 0-8.659-1.071-12.382-3.75l-0.029-6.039c5.049 3.85 11.348 5.657 17.402 3.352-1.114-1.212-1.794-2.83-1.794-4.607 0-3.762 3.049-6.811 6.811-6.811s6.812 3.049 6.812 6.811c0 1.44-0.447 2.778-1.212 3.879v0z"></path>{" "}
    </g>
  </svg>
);

export const LeoIcon = ({ color = "#000000", width = 44, height = 44 }) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title>leo</title>{" "}
      <path d="M10.534 19.088c0.778 0 1.408-0.63 1.408-1.408s-0.631-1.408-1.408-1.408c-0.778 0-1.408 0.63-1.408 1.408s0.63 1.408 1.408 1.408v0zM3.735 17.689c0-3.704 2.984-6.711 6.679-6.749-0.073-0.552-0.107-1.124-0.090-1.724 0.336-11.754 18.602-9.319 15.841 1.96-1.023 4.177-2.806 8.147-4.454 11.771-1.54 3.389 0.852 3.139 1.076 2.047l5.216 0.114c-0.19 8.692-16.137 6.891-11.042-4.319 1.671-3.676 3.055-7.273 4.136-10.853 1.236-4.096-8.159-5.63-4.772 3.104 0.486 1.253 0.907 2.792 0.9 4.308l0.008 0.341c0 3.727-3.022 6.749-6.749 6.749s-6.749-3.021-6.749-6.749z"></path>{" "}
    </g>
  </svg>
);

export const VirgoIcon = ({ color = "#000000", width = 44, height = 44 }) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title>virgo</title>{" "}
      <path d="M10.989 5.952c0-0.482-0.061-0.979-0.271-1.427-0.168-0.282-0.392-0.298-0.571-0.195-0.23 0.133-0.5 0.527-0.5 1.286 0.039 5.149-0.098 10.701-0.174 15.826h-5.058v-15.49c0-1.388-0.575-2.58-2.362-2.587l-0.546-0.002c1.131-1.288 2.256-1.842 4.075-1.842 1.172 0 2.267 0.464 3.024 1.254l0.123-0.111 0.144-0.124c1.089-0.84 2.337-1.019 3.283-1.019 1.17 0 2.263 0.463 3.020 1.251l0.118-0.107 0.144-0.124c1.089-0.84 2.337-1.019 3.284-1.019 2.145 0 4.021 1.553 4.062 3.793 0.023 1.244 0.021 2.897 0.006 4.698 0.495-1.4 1.461-3.175 3.185-3.175 1.423 0 4.269 0.071 4.269 8.752 0 6.296-3.272 9.892-5.536 11.652 0.292 0.113 0.599 0.159 0.911 0.159 1.566 0 2.846-1.281 2.846-1.281v1.779c0 0-0.534 1.743-4.447 1.743-1.166 0-2.117-0.188-2.893-0.52-2.411 0.769-5.324 1.196-5.324 1.196v-1.921c0 0 1.497-0.075 3.326-0.855-1.192-1.621-1.439-3.944-1.556-6.1l-0.014-0.516v-14.974c0-0.482-0.061-0.979-0.271-1.427-0.162-0.273-0.376-0.293-0.543-0.21-0.214 0.106-0.48 0.443-0.523 1.117 0.061 4.72-0.103 11.429-0.172 16.011h-5.058v-15.491l-0-0zM22.725 14.967l-0.002 0.128-0.009 0.528c-0.038 2.289-0.079 4.398-0.1 5.82 0 1.402 0.123 2.494 0.338 3.337 1.552-1.805 2.739-4.507 2.739-8.549 0-11.49-2.877-4.073-2.965-1.264z"></path>{" "}
    </g>
  </svg>
);

export const LibraIcon = ({ color = "#000000", width = 44, height = 44 }) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title>libra</title>{" "}
      <path d="M2.14 23.775h27.657v5.176h-27.657v-5.176zM18.267 19.604v-3.185c1.539-0.836 2.554-2.406 2.554-4.209 0-2.641-2.178-4.782-4.863-4.782s-4.863 2.141-4.863 4.782c0 1.781 0.99 3.335 2.459 4.158v3.236l-0.019 1.97h-11.395v-5.176h4.132c-0.623-1.278-0.971-2.7-0.971-4.199 0-5.556 4.775-10.060 10.666-10.060s10.666 4.504 10.666 10.060c0 1.499-0.347 2.921-0.971 4.199h4.133v5.176h-11.529l-0.001-1.97z"></path>{" "}
    </g>
  </svg>
);

export const ScorpioIcon = ({ color = "#000000", width = 44, height = 44 }) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title>scorpio</title>{" "}
      <path d="M23.235 29.122c-0.831 0-1.561-0.087-2.204-0.249-0.883-0.313-1.662-0.684-2.613-1.991-0.090-0.123-0.175-0.252-0.255-0.383l-0.054-0.090c-0.882-1.518-1.084-3.519-1.186-5.39l-0.013-0.496v-14.39c0-0.463-0.059-0.941-0.26-1.371-0.156-0.262-0.361-0.282-0.521-0.202-0.206 0.103-0.462 0.426-0.503 1.073 0.059 4.536-0.099 10.983-0.165 15.387h-4.861v-14.886c0-0.463-0.059-0.941-0.26-1.371-0.161-0.271-0.377-0.287-0.549-0.187-0.221 0.128-0.481 0.506-0.481 1.236 0.038 4.948-0.094 10.284-0.167 15.209h-4.861v-14.886c0-1.334-0.552-2.479-2.27-2.486l-0.525-0.002c1.087-1.238 2.168-1.77 3.916-1.77 1.126 0 2.179 0.446 2.906 1.205l0.118-0.107 0.138-0.119c1.046-0.807 2.246-0.979 3.155-0.979 1.124 0 2.175 0.445 2.903 1.202l0.114-0.103 0.138-0.119c1.047-0.807 2.246-0.979 3.156-0.979 2.061 0 3.864 1.492 3.903 3.645 0.010 0.552 0.016 1.188 0.017 1.883 0 3.909-0.174 14.831-0.045 15.612l0.063 0.346c0.032 0.161 0.067 0.314 0.104 0.461 0.61 2.353 1.492 2.893 3.093 2.858 0.715-0.064 1.352-0.33 1.847-0.612l-1.030-1.854 4.545 0.148-1.811 4.84c-0.014-0.054-0.388-0.745-0.856-1.597-0.423 0.554-1.593 1.516-4.627 1.516v0z"></path>{" "}
    </g>
  </svg>
);

export const SagittariusIcon = ({
  color = "#000000",
  width = 44,
  height = 44,
}) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title>sagittarius</title>{" "}
      <path d="M16.719 28.679l-4.993-4.997-6.259 6.247-3.589-3.589 6.255-6.255c-3.773-3.779-4.814-4.825-4.981-4.997l3.589-3.589 4.989 4.989 7.989-7.989c-8.603-2.15-6.671-1.674-6.972-1.753l1.228-4.925 12.718 3.171 3.122 12.706-4.942 1.232-1.67-6.714-7.875 7.869 4.993 4.993-3.602 3.601z"></path>{" "}
    </g>
  </svg>
);

export const CapricornIcon = ({
  color = "#000000",
  width = 44,
  height = 44,
}) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title>capricorn</title>{" "}
      <path d="M13.898 7.834c-1.010-0.167-1.41 4.941-1.557 7.381l-4.225 0.006c-0.18-6.466-0.266-7.103-1.246-8.824-0.448-0.787-1.769-0.904-1.769-0.904 0-0.839-0.008-4.27-0.008-4.27 2.327 0 3.94 1.68 4.956 3.528l0.151-0.34c0.494-1.057 1.674-3.119 3.999-3.119 7.332 0 2.667 20.162 9.077 18.5 0.414-0.107 1.066-1.451-0.246-1.451-2.371 0.315-3.254 13.143-10.941 12.105-0.736-0.099-1.18-0.301-1.18-0.301l0.531-4.565c1.412 0.176 3.375-0.944 3.628-1.661 1.070-3.033 2.799-10.486 8.353-10.486 2.122 0 4.54 2.054 4.54 5.661 0 5.892-10.582 9.615-12.989-3.082-0.514-2.706-0.269-8.379-1.074-8.177v0z"></path>{" "}
    </g>
  </svg>
);

export const AquariusIcon = ({
  color = "#000000",
  width = 44,
  height = 44,
}) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title>aquarius</title>{" "}
      <path d="M9.027 10.772l-6.778 4.784v-5.349l8.812-5.827 2.194 4.252 5.545-4.333 2.253 4.249 5.558-4.152 2.989 4.437-0.079 7.035-4.241-4.901-5.825 4.249-2.163-4.324-5.804 4.061-2.462-4.18zM9.027 22.885l-6.778 4.785v-5.349l8.812-5.827 2.194 4.251 5.545-4.332 2.253 4.249 5.558-4.152 2.989 4.438-0.079 7.035-4.241-4.901-5.825 4.249-2.163-4.324-5.804 4.061-2.462-4.181z"></path>{" "}
    </g>
  </svg>
);

export const PiscesIcon = ({ color = "#000000", width = 44, height = 44 }) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title>pisces</title>{" "}
      <path d="M17.535 13.806c0.431-4.96 2.499-9.276 5.487-12.014h5.475c-2.987 2.738-4.708 7.053-5.059 12.014h4.41v4.958h-4.309c0.523 4.567 2.253 8.503 5.085 11.034h-5.475c-2.832-2.531-4.861-6.468-5.491-11.034h-3.612c-0.63 4.567-2.659 8.503-5.491 11.034h-5.475c2.832-2.531 4.561-6.468 5.085-11.034h-4.667v-4.958h4.768c-0.352-4.96-2.072-9.276-5.059-12.014h5.476c2.987 2.738 5.056 7.053 5.486 12.014h3.366z"></path>{" "}
    </g>
  </svg>
);
