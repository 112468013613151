import React from "react";
import {
  AquariusIcon,
  AriesIcon,
  CancerIcon,
  CapricornIcon,
  GeminiIcon,
  LeoIcon,
  LibraIcon,
  PiscesIcon,
  SagittariusIcon,
  ScorpioIcon,
  TaurusIcon,
  VirgoIcon,
} from "../svg/zodiac-signs";

export const ZodiacSign = ({
  sign = "aries",
  color = "#000000",
  width = 44,
  height = 44,
}) => {
  switch (sign) {
    case "aries":
      return <AriesIcon color={color} width={width} height={height} />;
    case "taurus":
      return <TaurusIcon color={color} width={width} height={height} />;
    case "gemini":
      return <GeminiIcon color={color} width={width} height={height} />;
    case "cancer":
      return <CancerIcon color={color} width={width} height={height} />;
    case "leo":
      return <LeoIcon color={color} width={width} height={height} />;
    case "virgo":
      return <VirgoIcon color={color} width={width} height={height} />;
    case "libra":
      return <LibraIcon color={color} width={width} height={height} />;
    case "scorpio":
      return <ScorpioIcon color={color} width={width} height={height} />;
    case "sagittarius":
      return <SagittariusIcon color={color} width={width} height={height} />;
    case "capricorn":
      return <CapricornIcon color={color} width={width} height={height} />;
    case "aquarius":
      return <AquariusIcon color={color} width={width} height={height} />;
    case "pisces":
      return <PiscesIcon color={color} width={width} height={height} />;
    default:
      return null;
  }
};
